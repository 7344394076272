import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "sign-margin" }
const _hoisted_2 = { class: "sign-margin" }
const _hoisted_3 = { class: "sign-margin" }
const _hoisted_4 = { class: "sign-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar = _resolveComponent("toolbar")
  const _component_ion_label = _resolveComponent("ion-label")
  const _component_ion_input = _resolveComponent("ion-input")
  const _component_ion_item = _resolveComponent("ion-item")
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_button = _resolveComponent("ion-button")
  const _component_ion_text = _resolveComponent("ion-text")
  const _component_ion_content = _resolveComponent("ion-content")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_toolbar, {
        title: _ctx.$tr($setup.trSignIn, 'signUp'),
        backbutton: true
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, {
        fullscreen: "",
        class: "ion-padding",
        "scroll-y": "false"
      }, {
        default: _withCtx(() => [
          (!$data.needCode && !$data.loader)
            ? (_openBlock(), _createBlock("form", {
                key: 0,
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => ($options.register && $options.register(...args)), ["prevent"]))
              }, [
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { position: "floating" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'username')) + " *", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_input, {
                      modelValue: $data.form.email,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ($data.form.email = $event)),
                      id: "username",
                      required: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { position: "floating" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'password')) + " *", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_input, {
                      minlength: "10",
                      type: "password",
                      modelValue: $data.form.password,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ($data.form.password = $event)),
                      id: "email",
                      required: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { position: "floating" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tr($setup.trProfile, 'firstname')) + " *", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_input, {
                      modelValue: $data.form.firstName,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ($data.form.firstName = $event)),
                      id: "firstName",
                      required: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { position: "floating" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$tr($setup.trProfile, 'lastname')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_input, {
                      modelValue: $data.form.lastName,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ($data.form.lastName = $event)),
                      id: "lastName"
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode("section", _hoisted_1, [
                  _createVNode(_component_ion_button, {
                    type: "submit",
                    expand: "block"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'signUp')) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: $setup.personAdd
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _createVNode("section", {
                  class: "sign-margin",
                  innerHTML: _ctx.$tr($setup.trSignIn, 'conditions')
                }, null, 8, ["innerHTML"])
              ], 32))
            : (_openBlock(), _createBlock("form", {
                key: 1,
                onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => ($options.checkCode && $options.checkCode(...args)), ["prevent"]))
              }, [
                (!$data.showResend)
                  ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'confirmCode')) + " ", 1),
                              _createVNode("b", null, _toDisplayString($data.email), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, { position: "floating" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'enterCode')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_input, {
                            modelValue: $data.code,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ($data.code = $event)),
                            type: "tel",
                            id: "code",
                            required: ""
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode("section", _hoisted_2, [
                        _createVNode(_component_ion_button, {
                          type: "submit",
                          expand: "block"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'sendCode')), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ], 64))
                  : (_openBlock(), _createBlock(_Fragment, { key: 1 }, [
                      _createVNode(_component_ion_item, { lines: "none" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_text, {
                            innerHTML: _ctx.$tr($setup.trSignIn, 'codeExpired')
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      }),
                      _createVNode("section", _hoisted_3, [
                        _createVNode(_component_ion_button, {
                          color: "shiawasedo",
                          type: "button",
                          onClick: $options.register,
                          expand: "block"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'resendCode')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ], 64)),
                _createVNode("section", _hoisted_4, [
                  _createVNode(_component_ion_button, {
                    color: "light",
                    type: "button",
                    onClick: $options.cancel,
                    expand: "block"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$tr($setup.trSignIn, 'cancelCode')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ], 32))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}