
import {
  IonLabel,
  IonButton,
  IonInput,
  IonIcon,
  IonPage,
  IonContent,
  IonItem,
  loadingController,
  IonText,
  alertController
} from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons'
import { mapActions, mapGetters } from "vuex"
import {trSignIn, trProfile} from "@/i18n/messages";
import Toolbar from "@/components/Toolbar.vue";
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import {LanguageService} from "@/services/language.service";
import {trCommon} from "@/i18n/messages";

export default {
  name: 'Signup',
  components: {Toolbar, IonLabel, IonButton, IonInput, IonIcon, IonPage, IonContent, IonItem, IonText },
  setup() {
    return {
      logIn,
      personAdd,
      trSignIn,
      trProfile
    };
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        name: ""
      },
      email: localStorage.getItem('email') ? localStorage.getItem('email') : null,
      code: null,
      needCode: localStorage.getItem('needCode') ? localStorage.getItem('needCode') : false,
      loader: false,
      showResend: false
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticationError", "authenticationErrorCode"])
  },
  methods: {
    cancel() {
      localStorage.removeItem('needCode');
      localStorage.removeItem('email');
      this.needCode = false;
      this.email = false
    },
    ...mapActions("auth", ["signup"]),
    async register() {
      const loading = await loadingController.create({});
      await loading.present();
      if(this.email) {
        this.form.email = this.email;
      }
      ApiService.post('register', this.form).then(res => {
        if(res.data.data && res.data.data.email) {
          this.needCode = true;
          this.email = res.data.data.email;
          localStorage.setItem('needCode', this.needCode);
          localStorage.setItem('email', this.email);
          loading.dismiss();
          this.showResend = false
        }
      }).catch((error) => {
        const response = error.response;
        this.alertError(response.data.meta.error_message)
        loading.dismiss();
      })
    },
    async checkCode() {
      const loading = await loadingController.create({});
      await loading.present();
      ApiService.post('checkCode', {
        code: this.code,
        email: this.email
      }).then(res => {
        if(res.status == 200) {
          this.loader = true
          TokenService.saveToken(res.data.data.access_token);
          LanguageService.setLangById(res.data.data.langId);
          TokenService.saveUser(res.data.data.id);
          TokenService.saveRefreshToken(res.data.data.refresh_token);
          ApiService.setHeader();
          ApiService.mount401Interceptor();
          localStorage.removeItem('needCode');
          localStorage.removeItem('email');
          this.needCode = false;
          loading.dismiss();
          window.location.reload()
        }
      }).catch((error) => {
        const response = error.response;
        this.alertError(response.data.meta.error_message)
        if(response.data.meta.code === 406) {
          this.showResend = true
        }
        loading.dismiss();
      })
    },
    async alertError(text: any) {
      const alert = await alertController.create({
        header: this.$tr(trCommon, 'error'),
        message: text,
        buttons: ['OK'],
      });
      await alert.present();
    },
  }
}
